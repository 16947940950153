/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  IonButton,
  IonContent,
  IonFooter,
  IonLabel,
  IonLoading,
  IonText,
  IonToast,
} from '@ionic/react';
import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { Toaster, toast } from 'react-hot-toast';
import { closeOutline } from 'ionicons/icons';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../../hooks/authContext';
import getGraphQLClient, { DomainParams } from '../../../../hooks/graphQLClientUtil';
import { ApiStatus } from '../../../../pages/helper/Const';
import AutocompleteSearch from '../../../../molecule/AutocompleteSearch';
import StackedInput from '../../../../atom/StackedInput';
import SelectDateItem from '../../../../atom/SelectDateItem';
import { ASSIGN_EQUIPMENT_TO_CLAIM, ASSIGN_EQUIPMENTS_LIST_TO_CLAIM } from '../../data/graphql/AssignEquipment';
import GET_USER_SUGGESTIONS, { UserSuggestion } from '../../../../graphql/GetUserSuggestions';
import { GUID } from '../../../../graphql/GetUserProfileInfo';
import { EquipmentsType } from '../../data/graphql/GetEquipmentsList';
import FosSelectItem from '../../../../atom/FosSelectItem';
import { Option } from '../../../../utils/Interfaces';
import { Phase } from '../../../../types/Phases';
import { getErrorMsg } from '../../../../pages/helper/AppHelper';

type Props = {
  equipment: EquipmentsType;
  claim: any;
  equipmentsIds: number[];
  onAssignSuccess: () => any;
};

type FormData = {
  phaseIndx: number;
  deliveredBy: string;
};

const AssignEquimentForm: React.FC<Props> = ({ equipment, claim, equipmentsIds, onAssignSuccess }) => {
  const { t } = useTranslation();
  const { userState, checkTokenExpiration } = useAuth()!;
  const inputRef = useRef('');
  const regionId = userState.userAuthPayload?.regionId!;
  const graphQLClient = getGraphQLClient(userState);
  const currentDate = new Date().toISOString();
  const placeholderDate = new Date(currentDate);
  placeholderDate.setDate(placeholderDate.getDate() + 5); // adding 5 days as default date for returning the equipment
  const [returnDate, setNextDate] = useState(placeholderDate.toISOString());
  const [saveError, setSaveError] = useState('');
  const [suggestions, setSuggestions] = useState<Array<any>>([]);
  const phases = claim?.phases;
  const phaseOptions: Option[] = phases.map((phase: Phase) => ({ label: phase.phaseCode, value: phase.phaseIndx }));
  const isBatchScan = equipmentsIds.length > 0;
  const schema = yup.object({
    phaseIndx: yup
      .number()
      .required(t('phaseSelectionRequired'))
      .transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(false),
    deliveredBy: yup.string()
      .required(t('deliveredByRequired')),
  });

  const { handleSubmit, register, setValue, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  
  const getUsersSuggestions = useCallback((userInput: string) => {
    if (userInput.length > 0) {
      graphQLClient.request(GET_USER_SUGGESTIONS, { term: userInput }).then((res) => {
        const usersSuggestions = res?.userByNameTerm?.map((userSuggestion: UserSuggestion) => ({ id: userSuggestion.userGuid, fullName: userSuggestion.fullName }));
        setSuggestions(usersSuggestions);
      });
    } else {
      setSuggestions([]);
    }
  }, [graphQLClient]);

  const onSubmitHandler = handleSubmit((formData: FormData) => {
    // create the TransactionDtoInput object
    const transactionObj = {
      branchId: Number(equipment.transitBranch.id),
      claimIndx: claim.claimIndx,
      [isBatchScan ? 'itemsIds' : 'itemId']: isBatchScan ? equipmentsIds : equipment.id,
      monitored: true,
      phaseIndx: formData.phaseIndx,
      regionId,
      deliveredById: formData.deliveredBy,
      deviceDate: currentDate,
      returnDate,
      userId: localStorage.getItem(GUID),
    };
    onSubmit(transactionObj);
  });

  /* 
  * Submit form
  * */
  const onSubmit = async (trans: any) => {
    await checkTokenExpiration();
    mutateAsync(trans)
      .then((response) => {
        if (isBatchScan) {
          if (response?.assignEquipmentList?.status === ApiStatus.SUCCESS) {
            handleSuccess();
            return;
          }
          throw new Error(response.assignEquipment?.message || t('assignEquipmentError'));
        } else {
          if (response?.assignEquipment?.status === ApiStatus.SUCCESS) {
            handleSuccess();
            return;
          }
          throw new Error(response.assignEquipment?.message || t('assignEquipmentError'));
        }
      })
      .catch((e) => {
        const msg = getErrorMsg(e);
        const message = msg || t('assignEquipmentError');
        setSaveError(message);
      });
  };

  const handleSuccess = () => {
    toast.success(t('equipmentAssigned'), { duration: 2000 });
    setTimeout(() => {
      onAssignSuccess();
    }, 1000);
  };

  /*
  * Mutation to save the form
  * */
  const { isLoading: isSaving, mutateAsync } = useMutation({
    mutationFn: (trans: any) => 
      getGraphQLClient(userState, DomainParams.Equipments).request(
        isBatchScan ? ASSIGN_EQUIPMENTS_LIST_TO_CLAIM : ASSIGN_EQUIPMENT_TO_CLAIM,
        { trans }),
  });

  const onOptionSelect = (option?: any) => {
    if (option) {
      setValue('deliveredBy', option.id, { shouldValidate: true }); // This will trigger validation
    }
    setSuggestions([]);
    if (inputRef?.current) {
      // @ts-ignore
      inputRef.current.value = option.fullName ?? '';
    }
  };

  return (
    <>
      <IonContent className='ion-padding'>
        {!isBatchScan &&
          <StackedInput
            label={t('equipment')}
            disabled value={`${equipment?.itemNumber} - ${equipment?.itemClass.value} - ${equipment?.itemModel.value}`} />
        }
        {isBatchScan && 
          <IonLabel>
            <IonText>{t('batchScanSelected').toString()}</IonText>
          </IonLabel>
        }
        <AutocompleteSearch
          ref={inputRef}
          placeholder={`${t('equipmentDeliveredBy').toString()} *`}
          onChange={(evt: any) => {
            getUsersSuggestions(evt.target.value);
            if (errors.deliveredBy) {
              errors.deliveredBy.message = '';
            }
          }}
          options={suggestions}
          onOptionSelect={(option) => onOptionSelect(option)}
          debounce={1000}
          error={errors.deliveredBy?.message}
        />
        <input type="hidden" {...register('deliveredBy')} />
        <StackedInput label={t('equipmentClaim')} disabled value={claim.claimNumber} />
        <SelectDateItem
          id="currentDate"
          value={currentDate}
          label={t('equipmentDateAssigned')}
          presentation='date' />
        <SelectDateItem
          id="returnDate"
          value={returnDate}
          onIonChange={event => setNextDate(event.target.value)}
          label={`${t('equipmentDateReturn')} *`}
          presentation='date' />
        <form onSubmit={onSubmitHandler}>
          <FosSelectItem
            label={`${t('phase').toString()} *`}
            options={phaseOptions}
            error={errors.phaseIndx?.message}
            {...register('phaseIndx')} />
        </form>
      </IonContent >
      <IonFooter className='ion-padding'>
        <IonButton expand='block' fill='solid' onClick={onSubmitHandler}>{t('save').toString()}</IonButton>
      </IonFooter>
      <IonLoading isOpen={isSaving} message={t('saving')} duration={2000} />
      <IonToast
        isOpen={!!saveError}
        message={saveError}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => { setSaveError(''); }} />
      <Toaster
        containerStyle={{
          top: 50, left: 20, bottom: 20, right: 20,
        }} />
    </>
  );
};

export default AssignEquimentForm;
