import { gql } from '@apollo/client';

export const BRANCH_TRANSFER_EQUIPMENT = gql`
mutation TransferItem ($trans: TransferItemDtoInput) {
    transferItem(
        trans: $trans
    ) {
        itemId
        status
        message
        faultType
        actionNotice
    }
}`;

