/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import {
  IonContent,
  IonHeader,
  IonList,
  IonLoading,
  IonPage,
  IonToast,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  useIonActionSheet,
  isPlatform,
  IonModal,
} from '@ionic/react';
import { filterOutline, barcode, add, closeOutline } from 'ionicons/icons';
import { t } from 'i18next';
import { Toaster } from 'react-hot-toast';
import FosButtonIcon, { FosButtonIconStyle } from '../../../../../atom/FosButtonIcon';
import SearchInput from '../../../../../atom/SearchInput';
import { EquipmentsType } from '../../../data/graphql/GetEquipmentsList';
import FosDocItem from '../../../../../atom/FosDocItem';
import '../equipmentsclass/EquipmentsByClasses.css';
import useEquipmentViewModel from './EquipmentsViewModel';
import useEquipmentByClassesViewModel from '../equipmentsclass/EquipmentsByClassesViewModel';
import FilterModal from '../../../../../organism/FilterModal';
import FosMultiSelectItem from '../../../../../components/FosMultiSelectItem';
import FosCard from '../../../../../atom/FosCard';
import { EquipmentStatus } from '../../../../../pages/helper/Const';
import { EquipmentAction } from '../../../util/EquipmentUtil';
import { HYBRID } from '../../../../../hooks/photo';
import { hideReturnToBranchStatus } from '../../../../../pages/helper/EquipmentsHelper';
import FosModalHeader from '../../../../../atom/FosModalHeader';
import BranchTransferForm from '../../branchTransfer/BranchTransferForm';
import ReturnBranchForm from '../../returnBranch/ReturnBranchForm';

type Props = {
  collapse?: 'condense' | 'fade';
};

const Equipments: React.FC<Props> = ({ collapse }) => {
  
  const [present] = useIonActionSheet();
  const filterModal = useRef<HTMLIonModalElement>(null);

  const {
    isFetching,
    error,
    equipByClassError,
    xssErrorEquipment,
    equipmentClasses,
    scanActionSheetButtons,
    selectedModel,
    selectedBranch,
    selectedStatus,
    equipmentsSubset,
    equipmentBranchesOptions,
    equipmentStatusOptions,
    equipmentModelsOptions,
    isFilteredOrSearched,
    showResults,
    clearFilter,
    handleModelChange,
    handleBranchChange,
    handleStatusChange,
    handleOnDidDismissScan,
    onSearchTerm,
    navigateToEquipmentsByClass,
    navigateToAddEquipment,
  } = useEquipmentViewModel();

  const {
    EQUIPMENT_ACTION_SHEET,
    isModalOpen,
    selectedEquipment,
    isReturnEquipmentModalOpen,
    setIsReturnEquipmentModalOpen,
    handleOnReturnBranchSuccess,
    setIsModalOpen,
    onEditClick,
    handleItemSelection,
    handleOnDidDismiss,
    handleOnTransferSuccess,
  } = useEquipmentByClassesViewModel();

  const showScanOptionsSheet = () => {
    present({
      header: t('chooseType'),
      cssClass: 'custom-action-sheet',
      buttons: scanActionSheetButtons,
      onDidDismiss: ({ detail }) => handleOnDidDismissScan(detail.data?.action),
    });
  };

  const dismissModal = () => filterModal.current?.dismiss();

  const handleClearFilter = () => {
    clearFilter();
    dismissModal();
  };

  const handleShowResults = () => {
    showResults();
    dismissModal();
  };

  const getEquipmentActionsSheets = (equipment: EquipmentsType) => {
    if (Number(equipment?.status?.id) === EquipmentStatus.Available) {
      return EQUIPMENT_ACTION_SHEET;
    } 
    if (hideReturnToBranchStatus(Number(equipment.status.id))) {
      return [{
        text: t('cancel'),
        role: 'destructive',
        data: {
          action: EquipmentAction.CANCEL,
        },
      }];
    }
    return [{
      text: t('returnToBranch'),
      data: {
        action: EquipmentAction.ReturnToBranch,
      },
    },
    {
      text: t('cancel'),
      role: 'destructive',
      data: {
        action: EquipmentAction.CANCEL,
      },
    }];
  };

  const onStatusCLick = (equipment: EquipmentsType) => {
    present({
      header: t('chooseStatus'),
      cssClass: 'custom-action-sheet',
      buttons: getEquipmentActionsSheets(equipment),
      onDidDismiss: ({ detail }) => handleOnDidDismiss(detail.data?.action, equipment),
    });
  };

  return (
    <IonPage>
      <IonHeader className="equipment-header" collapse={collapse} mode="ios">
        <IonToolbar className='equipment-custom-ion-toolbar'>
          <div className="ion-padding-horizontal equipment-toolbar">
            <SearchInput id="search-equipment-bar" debounce={1000} onSearch={onSearchTerm} />
            <FosButtonIcon id='equipmentFilterModal' icon={filterOutline} />
            {isPlatform(HYBRID) && <FosButtonIcon icon={barcode} style={FosButtonIconStyle.Orange} onClick={showScanOptionsSheet} />}
            <FosButtonIcon icon={add} style={FosButtonIconStyle.Blue} onClick={navigateToAddEquipment} />
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className='equipment-body ion-padding'>
        {
          isFilteredOrSearched ? 
          <IonGrid>
          <IonList lines='none'>
          {equipmentsSubset.length === 0 && !isFetching ?
              <p className="ion-text-center">{t('noRecord').toString()}</p> : 
            <IonRow>
              {equipmentsSubset.map((equipment, index) => (
                  <IonCol
                    key={`${equipment.itemNumber + index}`}
                    className='ion-padding-horizontal equipment-list-item-padding-bottom'
                    size="12"
                    size-md="6"
                    size-lg="4">
                    <FosCard
                      status={equipment.status.value}
                      title={`${equipment.itemClass.value} - ${equipment.itemModel.value}`}
                      subtitle={equipment.itemNumber}
                      location={equipment.branch.value}
                      leftButtonLabel={t('status').toString()}
                      rightButtonLabel={t('edit').toString()}
                      hasMinHeight={false}
                      onLeftButtonClick={() => onStatusCLick(equipment)}
                      onRightButtonClick={() => onEditClick(equipment.id)}
                      onClick={() => handleItemSelection(equipment)} />
                  </IonCol>
              ))}
              </IonRow>}
            </IonList>
          </IonGrid> :
          <IonList lines='none'>
          { equipmentClasses.length === 0 && !isFetching ?
           <p className="ion-text-center">{t('noRecord').toString()}</p> :
            equipmentClasses.map((equipment, index) => (
              <div key={index}>
                <FosDocItem
                  label={`${equipment.class} (${equipment.count})`}
                  onItemClick={() => navigateToEquipmentsByClass(equipment.equipmentClassId)}
                  onArrowClick={() => { }}
                />
              </div>
            ))}
          </IonList>
        }          
        <IonLoading isOpen={isFetching} message={t('loading')} duration={2000} />
      </IonContent>
      <FilterModal
        ref={filterModal}
        trigger="equipmentFilterModal"
        onClear={handleClearFilter}
        onClose={dismissModal}
        onShowResults={handleShowResults}>
        <>
          <FosMultiSelectItem
            title={t('itemModel').toString()}
            options={equipmentModelsOptions}
            value={selectedModel}
            onChange={handleModelChange} />
          <FosMultiSelectItem
            title={t('branch').toString()}
            options={equipmentBranchesOptions}
            value={selectedBranch}
            onChange={handleBranchChange} />
          <FosMultiSelectItem
            title={t('status').toString()}
            options={equipmentStatusOptions}
            value={selectedStatus}
            onChange={handleStatusChange} />
        </>
      </FilterModal>
      <IonModal isOpen={isModalOpen} class="custom-modal">
        <FosModalHeader title='Transfer Equipment' onCloseClick={() => setIsModalOpen(false)} />
        <BranchTransferForm
          equipment={selectedEquipment}
          onBranchTransferSuccess={handleOnTransferSuccess} />
      </IonModal>
      <IonModal isOpen={isReturnEquipmentModalOpen} class="custom-modal">
        <FosModalHeader title='Return to Branch' onCloseClick={() => setIsReturnEquipmentModalOpen(false)} />
        <ReturnBranchForm
          equipment={selectedEquipment}
          onReturnBranchSuccess={handleOnReturnBranchSuccess} />
      </IonModal>
      <IonToast
        isOpen={!!error && !equipByClassError}
        message={t('tryAgain')}
        buttons={[{ role: 'cancel', icon: closeOutline }]} />
      <IonToast
        isOpen={!!xssErrorEquipment}
        message={t('errorXSS')}
        buttons={[{ role: 'cancel', icon: closeOutline }]} />
      <Toaster
        containerStyle={{
          top: 50,
          left: 20,
          bottom: 20,
          right: 20,
        }} />
    </IonPage>
  );
};


export default Equipments;
