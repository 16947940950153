/* eslint-disable @typescript-eslint/no-use-before-define */
import { IonButton, IonContent, IonFooter, IonLoading, IonToast } from '@ionic/react';
import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { Toaster, toast } from 'react-hot-toast';
import { closeOutline } from 'ionicons/icons';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../../hooks/authContext';
import getGraphQLClient, { DomainParams } from '../../../../hooks/graphQLClientUtil';
import { ApiStatus } from '../../../../pages/helper/Const';
import AutocompleteSearch from '../../../../molecule/AutocompleteSearch';
import StackedInput from '../../../../atom/StackedInput';
import SelectDateItem from '../../../../atom/SelectDateItem';
import GET_USER_SUGGESTIONS, { UserSuggestion } from '../../../../graphql/GetUserSuggestions';
import { GUID } from '../../../../graphql/GetUserProfileInfo';
import { EquipmentsType } from '../../data/graphql/GetEquipmentsList';
import FosSelectItem from '../../../../atom/FosSelectItem';
import { getErrorMsg } from '../../../../pages/helper/AppHelper';
import { BRANCH_TRANSFER_EQUIPMENT } from '../../data/graphql/BranchTransfer';
import useStorage from '../../../../hooks/storage';
import { EquipmentInfoType } from '../../data/graphql/GetEquipmentInfo';
import useEquipmentBranches from '../../data/hooks/useEquipmentBranches';

type Props = {
  equipment: EquipmentsType | EquipmentInfoType;
  onBranchTransferSuccess: () => any;
};

type FormData = {
  deliveredBy: string;
  branchId: string;
};

const BranchTransferForm: React.FC<Props> = ({ equipment, onBranchTransferSuccess }) => {
  const { t } = useTranslation();
  const { userState, checkTokenExpiration } = useAuth()!;
  const inputRef = useRef('');
  const regionId = userState.userAuthPayload?.regionId!;
  const graphQLClient = getGraphQLClient(userState);
  const [dateAssigned, setDateAssigned] = useState(new Date().toISOString());
  const [saveError, setSaveError] = useState('');
  const [suggestions, setSuggestions] = useState<Array<any>>([]);
  const schema = yup.object({
    branchId: yup.string().required(t('destinationBranchIsRequired')),
    deliveredBy: yup.string()
      .required(t('deliveredByRequired')),
  });

  const equipmentBranches = useEquipmentBranches(regionId, userState, useStorage());
  const brancheOptions = equipmentBranches.filter(e => Number(e.id) !== Number(equipment?.branch?.id)).map(branch => ({ label: branch.value, value: branch.id }));
  const equipmentBranchName = equipment?.branch?.value;

  const { handleSubmit, register, setValue, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  
  const getUsersSuggestions = useCallback((userInput: string) => {
    if (userInput.length > 0) {
      graphQLClient.request(GET_USER_SUGGESTIONS, { term: userInput }).then((res) => {
        const usersSuggestions = res?.userByNameTerm?.map((userSuggestion: UserSuggestion) => ({ id: userSuggestion.userGuid, fullName: userSuggestion.fullName }));
        setSuggestions(usersSuggestions);
      });
    } else {
      setSuggestions([]);
    }
  }, [graphQLClient]);

  const onSubmitHandler = handleSubmit((formData: FormData) => {
    // create the TransactionDtoInput object
    const transactionObj = {
      regionId,
      itemId: equipment.id,
      branchId: Number(formData.branchId),
      deliveredById: formData.deliveredBy,
      issueDate: dateAssigned,
      userId: localStorage.getItem(GUID),
    };
    onSubmit(transactionObj);
  });

  /* 
  * Submit form
  * */
  const onSubmit = async (trans: any) => {
    await checkTokenExpiration();
    mutateAsync(trans)
      .then((response) => {
        if (response?.transferItem?.status === ApiStatus.SUCCESS) {
          handleSuccess();
          return;
        }
        throw new Error(response.assignEquipment?.message || t('transferEquipmentError'));
        
      })
      .catch((e) => {
        const msg = getErrorMsg(e);
        const message = msg || t('transferEquipmentError');
        setSaveError(message);
      });
  };

  const handleSuccess = () => {
    toast.success(t('equipmentTransfered'), { duration: 2000 });
    setTimeout(() => {
      onBranchTransferSuccess();
    }, 1000);
  };

  /*
  * Mutation to save the form
  * */
  const { isLoading: isSaving, mutateAsync } = useMutation({
    mutationFn: (trans: any) => 
      getGraphQLClient(userState, DomainParams.Equipments).request(
        BRANCH_TRANSFER_EQUIPMENT,
        { trans }),
  });

  const onOptionSelect = (option?: any) => {
    if (option) {
      setValue('deliveredBy', option.id, { shouldValidate: true }); // This will trigger validation
    }
    setSuggestions([]);
    if (inputRef?.current) {
      // @ts-ignore
      inputRef.current.value = option.fullName ?? '';
    }
  };

  return (
    <>
      <IonContent className='ion-padding'>
        <form onSubmit={onSubmitHandler}>
          <StackedInput
            label={t('equipment')}
            disabled value={`${equipment?.itemNumber} - ${equipment?.itemClass.value} - ${equipment?.itemModel.value}`} />
          <StackedInput label={t('transferringFrom')} disabled value={equipmentBranchName} />
          <FosSelectItem
            label={`${t('transferringTo').toString()} *`}
            options={brancheOptions}
            error={errors.branchId?.message}
            {...register('branchId')}
          />
          <AutocompleteSearch
            ref={inputRef}
            placeholder={`${t('equipmentDeliveredBy').toString()} *`}
            onChange={(evt: any) => {
              getUsersSuggestions(evt.target.value);
              if (errors.deliveredBy) {
                errors.deliveredBy.message = '';
              }
            }}
            options={suggestions}
            onOptionSelect={(option) => onOptionSelect(option)}
            debounce={1000}
            error={errors.deliveredBy?.message}
          />
          <input type="hidden" {...register('deliveredBy')} />
          <div><br /></div>
          <SelectDateItem
            id="dateAssigned"
            value={dateAssigned}
            onIonChange={event => setDateAssigned(event.target.value)}
            label={`${t('equipmentDateAssigned')} *`}
            presentation='date' />
        </form>
      </IonContent >
      <IonFooter className='ion-padding'>
        <IonButton expand='block' fill='solid' onClick={onSubmitHandler}>{t('save').toString()}</IonButton>
      </IonFooter>
      <IonLoading isOpen={isSaving} message={t('saving')} duration={2000} />
      <IonToast
        isOpen={!!saveError}
        message={saveError}
        buttons={[{ role: 'cancel', icon: closeOutline }]}
        onDidDismiss={() => { setSaveError(''); }} />
      <Toaster
        containerStyle={{
          top: 50, left: 20, bottom: 20, right: 20,
        }} />
    </>
  );
};

export default BranchTransferForm;
