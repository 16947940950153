import { gql } from '@apollo/client';

export const RETURN_EQUIPMENT = gql`
mutation ReturnEquipment ($trans: ReturnEquipmentDtoInput) {
    returnEquipment(
        trans: $trans
    ) {
        itemId
        status
        message
        faultType
        actionNotice
        errors {
            value
        }
        warnings {
            value
        }
    }
}`;

